.contact__section {
  margin-top: 5rem;
}

.contact__section .heading__one {
  text-align: center;
  margin-bottom: 2rem;
}

.contact__section form {
  width: 40%;
  background-color: var(--white);
  box-shadow: 0 1px 6px var(--gray);
  padding: 2rem;
  border-radius: 10px;
  margin: auto;
}

.contact__section form .input__container {
  display: flex;
  flex-direction: column;
  font-family: var(--inter);
  margin-bottom: 1rem;
}

.contact__section form .input__container label {
  font-size: 15px;
  color: var(--black);
  margin-bottom: 5px;
  font-weight: 500;
}

.contact__section form .input__container input,
.contact__section form .input__container textarea {
  border-radius: 10px;
  height: 45px;
  padding: 0 1rem;
  outline: none;
  border: none;
  border: 1px solid var(--gray);
  font-family: var(--inter);
  font-size: 14px;
  color: var(--black);
}

.contact__section form .input__container textarea {
  height: 200px;
  resize: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.contact__section form button {
  background-color: var(--red);
  color: var(--white);
  margin-left: auto;
  padding: 0 1rem;
}

@media only screen and (max-width: 1280px) {
  .contact__section form {
    width: 70%;
  }
}

@media only screen and (max-width: 1280px) {
  .contact__section {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 640px) {
  .contact__section {
    padding: 0 1rem;
  }

  .contact__section form {
    width: 100%;
    padding: 1rem;
  }

  .contact__section form .input__container label {
    font-size: 14px;
  }

  .contact__section form .input__container textarea {
    height: 150px;
  }
}

/* Styling for error messages */
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
