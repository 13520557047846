.portfolio__container {
  width: 1140px;
  margin: 5rem auto 0 auto;
}

.portfolio__container .heading__one {
  text-align: center;
  margin-bottom: 2rem;
}

.portfolio__container .portfolio__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.portfolio__cards .portfolio__card {
  background-color: var(--white);
  box-shadow: 0 1px 6px var(--gray);
  padding: 1.2rem;
  border-radius: 10px;
}

.portfolio__cards .portfolio__card img {
  border-radius: 10px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.portfolio__cards .portfolio__card .heading__two {
  font-size: 22px;
  font-family: var(--inter);
  font-weight: 600;
  margin: 1rem 0 1.5rem 0;
}

.portfolio__cards .portfolio__card .portfolio__buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.portfolio__cards .portfolio__card .portfolio__buttons a {
  text-decoration: none;
}

.portfolio__cards .portfolio__card .portfolio__buttons a:first-of-type button {
  background-color: var(--red);
  color: var(--white);
}

.portfolio__cards .portfolio__card .portfolio__buttons a:last-of-type button {
  background-color: var(--white);
  color: var(--red);
  border: 1px solid var(--red);
  transition: all 0.2s linear;
}

.portfolio__cards
  .portfolio__card
  .portfolio__buttons
  a:last-of-type
  button:hover {
  background-color: var(--red);
  border-color: transparent;
  color: var(--white);
}

@media only screen and (max-width: 1280px) {
  .portfolio__container {
    width: 100%;
    padding: 0 2rem;
    gap: 2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .portfolio__container .portfolio__cards {
    gap: 1rem;
  }

  .portfolio__cards .portfolio__card .portfolio__buttons {
    flex-direction: column;
  }

  .portfolio__cards .portfolio__card .portfolio__buttons button,
  .portfolio__cards .portfolio__card .portfolio__buttons a {
    width: 100%;
    justify-content: center;
  }

  .portfolio__cards .portfolio__card .heading__two {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio__container {
    padding: 0 1rem;
    margin-top: 2rem;
  }

  .portfolio__container .portfolio__cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .portfolio__cards .portfolio__card .portfolio__buttons {
    flex-direction: row;
  }
}

@media only screen and (max-width: 640px) {
  .portfolio__container .portfolio__cards {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }
}
