header {
  padding: 1rem;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 1000;
}

header.sticky {
  box-shadow: 0 1px 6px var(--gray);
}

header nav {
  width: 1140px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header nav picture img {
  width: 130px;
}

header nav menu {
  display: flex;
  gap: 3rem;
  font-family: var(--inter);
  font-weight: 500;
}

header nav menu a {
  height: 30px;
  position: relative;
  cursor: pointer;
  color: black; /* Set color to black */
  text-decoration: none; /* Removes the underline from the link */
}

header nav menu a::before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  bottom: 0;
  background-color: var(--red);
  transition: all 0.2s linear;
}

header nav menu a:hover::before,
header nav menu a.active::before {
  width: 100%;
}

header nav .mobile__menu__icon {
  display: none;
  font-size: 24px;
}

@media only screen and (max-width: 1280px) {
  header nav {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  header {
    padding: 0.5rem 1rem;
  }

  header nav .mobile__menu__icon {
    display: block;
  }

  header nav menu {
    position: absolute;
    flex-direction: column;
    width: 100%;
    background-color: var(--white);
    top: 50px;
    left: 0;
    padding: 2rem 2rem 1rem 2rem;
    gap: 1.5rem;
    font-size: 15px;
    box-shadow: 0 8px 6px rgba(0, 0, 0, 0.089);
    transform-origin: top;
    transform: scaleY(0);
    transition: all 0.2s ease-in;
  }

  header nav menu.show {
    transform: scaleY(1);
  }

  header nav picture img {
    width: 100px;
  }
}
