.experience__container {
  width: 1140px;
  margin: 5rem auto 0 auto;
}

.experience__container .heading__one {
  text-align: center;
  margin-bottom: 4rem;
}

.experience__container .resume__download__button {
  text-decoration: none;
  display: block;
  width: max-content;
  margin: auto;
  margin-bottom: 2rem; /* Added some margin for spacing */
}

.experience__container .resume__download__button button {
  background-color: var(--red);
  color: var(--white);
  padding: 0 1rem;
}

.proficiencies {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.proficiencies__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* This ensures even spacing between each proficiency item */
  width: 100%;
  max-width: 1200px;
  list-style: none;
  padding: 0; /* Resetting any default padding */
}


.proficiency__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0; /* Adding vertical margin for even spacing */
  padding: 10px; /* Adding padding for spacing around the content */
  /* Ensure a consistent height for icons, even if missing */
  height: 40px; 
  justify-content: center;
}

.proficiency__icon {
  font-size: 30px;
  color: var(--black); /* Adjust this to make it black */
  margin-bottom: 10px;
}

.proficiency__name {
  font-size: 20px;
  color: var(--black); /* Adjust this to make it black */
  text-align: center; /* Center the text */
}

.experience__container .skill__list {
  display: flex;
  justify-content: space-around;
}

.experience__container .skill__list .skill__single__list .item {
  position: relative;
  margin-bottom: 3rem;
}

.experience__container .skill__list .skill__single__list .item .heading__three {
  font-size: 18px;
  font-family: var(--inter);
  font-weight: 500;
  margin-bottom: 5px;
}

.experience__container
  .skill__list
  .skill__single__list
  .item:not(:last-child)::before {
  width: 0;
  height: 150%;
  content: "";
  position: absolute;
  bottom: -100%;
  left: -50%;
  border-left: 2px dashed var(--gray);
}

.experience__container
  .skill__list
  .skill__single__list
  .item:last-child::before {
  top: -100%;
}

.skill__list .skill__single__list .item .circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  left: calc(-50% - 12px);
  top: 50%;
  transform: translateY(-50%);
}

.skill__list .skill__single__list .item .circle.color__one {
  background-color: var(--green);
}

.skill__list .skill__single__list .item .circle.color__two {
  background-color: var(--blue);
}

.skill__list .skill__single__list .item .circle.color__three {
  background-color: var(--yellow);
}

.skill__list .skill__single__list .item .circle.color__default {
  background-color: var(--green);
}

.skill__list .skill__single__list .item .circle .inner__circle {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px dashed var(--gray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1280px) {
  .experience__container {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .experience__container .skill__list .skill__single__list .item {
    margin-bottom: 2rem;
  }

  .experience__container
    .skill__list
    .skill__single__list
    .item
    .heading__three {
    font-size: 16px;
  }

  .experience__container
    .skill__list
    .skill__single__list
    .item:not(:last-child)::before {
    border-width: 2px;
    left: -30%;
    bottom: -110%;
  }

  .skill__list .skill__single__list .item .circle {
    width: 16px;
    height: 16px;
    left: calc(-30% - 7px);
  }

  .skill__list .skill__single__list .item .circle .inner__circle {
    width: 25px;
    height: 25px;
  }

  .experience__container .heading__one {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .proficiencies__list {
    grid-template-columns: repeat(2, 1fr); /* Reduce to 2 columns on smaller screens */
    gap: 10px; /* Reduced gap for smaller screens */
  }

  .proficiency__item {
    margin: 10px 0; /* Adjust vertical margin */
    padding: 5px; /* Adjust padding */
  }

  .proficiency__icon {
    font-size: 24px; /* Reduce font size for icons */
  }

  .proficiency__name {
    font-size: 16px; /* Reduce font size for text */
  }

  .experience__container {
    margin-top: 3rem;
    overflow: hidden;
  }

  .experience__container .skill__list {
    flex-direction: column;
    align-items: center;
  }

  .experience__container .skill__list .skill__single__list .item {
    margin-bottom: 1.5rem;
  }

  .experience__container
    .skill__list
    .skill__single__list
    .item
    .heading__three {
    margin-bottom: 0;
  }

  .experience__container .skill__list .skill__single__list .item {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .proficiencies__list {
    grid-template-columns: repeat(1, 1fr); /* Single column layout for mobile */
  }
}
