.about__container p {
  color: #797979;
}

.about__content {
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the children horizontally */
  justify-content: center; /* This centers the children vertically */
}

.heading__one {
  color: black;
  text-decoration: underline;
  text-align: center;
}

a.button-link {
  text-decoration: none !important;
  color: inherit !important;
}

.about__container {
  width: 1140px;
  margin: 5rem auto 0 auto;
  display: flex;
  gap: 4rem;
}

.about__container picture,
.about__container .about__content {
  flex: 1;
}

.about__container picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.about__container .about__content .heading__one {
  margin-bottom: 1rem;
}

.about__container .about__content p {
  margin-bottom: 1rem;
}

.about__container .about__content button {
  background-color: var(--red);
  color: var(--white);
}

@media only screen and (max-width: 1280px) {
  .about__container {
    margin-top: 3rem;
    width: 100%;
    padding: 0 2rem;
    gap: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .about__container {
    margin-top: 2rem;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }

  .about__container .about__content button {
    margin: auto;
    padding: 1rem;
  }
}

@media only screen and (max-width: 640px) {
  .about__container {
    margin-top: 0;
  }

  .about__container .about__content p {
    font-size: 15px;
  }
}
